
.wr-default-bg.wr-bg-full-screen , .wr-default-bg, .preview-agreement {
    display: none;
}

@font-face {
  font-family: 'Gilroy';
  src: url('assets/fonts/gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('assets/fonts/gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'Gilroy', Arial, sans-serif;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
