@import "primeicons/primeicons.css";

@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}


.iti {
  --iti-path-flags-1x: url('/assets/intl-tel-input2/build/img/flags.webp');
  --iti-path-flags-2x: url('/assets/intl-tel-input2/build/img/flags@2x.webp');
  --iti-path-globe-1x: url('/assets/intl-tel-input2/build/img/globe.webp');
  --iti-path-globe-2x: url('/assets/intl-tel-input2/build/img/globe@2x.webp');
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("/assets/fonts/gilroy/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Bold";
  src: url("/assets/fonts/gilroy/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("/assets/fonts/gilroy/Gilroy-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: Gilroy-Medium;
  src: url("/assets/fonts/gilroy/Gilroy-Medium.ttf") format("truetype");
}
